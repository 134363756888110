import type { VariantProps} from "cva";
import { cva } from "cva";
import * as React from "react";
import { useEffect, useId, useImperativeHandle, useRef } from "react";
import { HStack } from "../../core/Stack";
import { cn } from "../../utils";
import { Label } from "../Label";

const checkboxStyles = cva("tw-rounded tw-border-zinc-300 ", {
  variants: {
    size: {
      sm: "tw-h-3 tw-w-3",
      md: "tw-h-4 tw-w-4",
      lg: "tw-h-5 tw-w-5",
    },
    colorScheme: {
      indigo: "tw-text-indigo-600 focus:tw-ring-indigo-600",
      zinc: "tw-text-zinc-700 focus:tw-ring-zinc-700",
    },
  },
  defaultVariants: {
    size: "md",
    colorScheme: "zinc",
  },
});

export interface CheckboxInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
    VariantProps<typeof checkboxStyles> {
  indeterminate?: boolean;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ className, indeterminate, size, ...props }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(
      ref,
      () => {
         
        return innerRef.current!;
      },
      [],
    );

    useEffect(() => {
      if (innerRef && innerRef.current) {
        innerRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    return (
      <input
        type="checkbox"
        className={cn(checkboxStyles({ size }), className)}
        ref={innerRef}
        {...props}
      />
    );
  },
);

export type CheckboxWithLabelProps = Omit<CheckboxInputProps, "id"> & {
  children: React.ReactNode;
};

export const CheckboxWithLabel = React.forwardRef<
  HTMLInputElement,
  CheckboxInputProps
>(({ children, size, ...other }, ref) => {
  const id = useId();
  return (
    <HStack spacing={2} className="tw-items-center">
      <Checkbox size={size} {...other} id={id} ref={ref} />
      <Label htmlFor={id} size={size}>
        {children}
      </Label>
    </HStack>
  );
});

Checkbox.displayName = "Checkbox";
