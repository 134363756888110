import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "cva";
import {
  AlertTriangle as IconAlertTriangle,
  CheckCircle2 as IconCircleCheck,
  AlertCircle as IconExclamationCircle,
  Info as IconInfoCircle,
} from "lucide-react";
import * as React from "react";
import { isValidElement } from "react";
import { cn } from "../../utils";
import { HStack, VStack } from "../Stack";
import type { AlertContextValue } from "./AlertContext";
import { AlertContext, useAlertContext } from "./AlertContext";

const alertWrapper = cva(
  "tw-rounded-md tw-p-4 tw-items-center tw-justify-center",
  {
    variants: {
      variant: {
        error: "tw-bg-red-50 tw-text-red-800",
        success: "tw-bg-green-50 tw-text-green-800",
        warning: "tw-bg-yellow-50 tw-text-yellow-800",
        info: "tw-bg-blue-50 tw-text-blue-800",
      },
    },
    defaultVariants: {
      variant: "info",
    },
  },
);

const alertTitle = cva("tw-text-sm tw-font-medium mb-0", {
  variants: {
    variant: {
      error: "tw-text-red-800",
      success: "tw-text-green-800",
      warning: "tw-text-yellow-800",
      info: "tw-text-blue-800",
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

const alertDescription = cva("tw-text-sm", {
  variants: {
    variant: {
      error: "tw-text-red-700",
      success: "tw-text-green-700",
      warning: "tw-text-yellow-700",
      info: "tw-text-blue-700",
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

export type AlertProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof alertWrapper> &
  AlertContextValue & {
    icon?: React.ReactNode | null;
  };

const Alert = ({
  className,
  children,
  dismissible,
  icon,
  variant,
  ...props
}: AlertProps) => {
  let title: React.ReactNode,
    description: React.ReactNode,
    actions: React.ReactNode;

  React.Children.toArray(children).forEach((item) => {
    if (isValidElement(item)) {
      if (item.type === AlertTitle) {
        title = item;
      }
      if (item.type === AlertDescription) {
        description = item;
      }
      if (item.type === AlertActions) {
        actions = item;
      }
    }
  });

  return (
    <AlertContext.Provider value={{ variant, dismissible }}>
      <HStack
        spacing={3}
        role="alert"
        className={cn(alertWrapper({ variant }), className)}
        {...props}
      >
        <div className="tw-self-start tw-mt-0.5">{icon ?? <AlertIcon />}</div>
        <VStack spacing={1}>
          {title}
          {description}
        </VStack>
        <div className="tw-ml-2">{actions}</div>
      </HStack>
    </AlertContext.Provider>
  );
};

Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  const { variant } = useAlertContext();
  return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h5
      ref={ref}
      className={cn(alertTitle({ variant }), className)}
      {...props}
    />
  );
});

AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { asChild?: boolean }
>(({ className, asChild, ...props }, ref) => {
  const { variant } = useAlertContext();
  const Comp = asChild ? Slot : "div";
  return (
    <Comp
      ref={ref}
      className={cn(alertDescription({ variant }), className)}
      {...props}
    />
  );
});

AlertDescription.displayName = "AlertDescription";

const AlertActions = HStack;

const icons: {
  [key in AlertProps["variant"]]: React.ReactNode;
} = {
  error: <IconExclamationCircle size={18} />,
  warning: <IconAlertTriangle size={18} />,
  success: <IconCircleCheck size={18} />,
  info: <IconInfoCircle size={18} />,
};

const AlertIcon = () => {
  const { variant } = useAlertContext();
  return <>{icons[variant]}</>;
};

export { Alert, AlertTitle, AlertDescription, AlertActions, AlertIcon };
