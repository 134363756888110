import { ExternalLink as IconExternalLink } from "lucide-react";
import type { HTMLProps } from "react";
import { cn } from "../../utils";

export function ExternalLink({
  children,
  className,
  ...props
}: HTMLProps<HTMLAnchorElement>) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      {...props}
      className={cn(
        "tw-text-teal-600 hover:tw-text-teal-500 tw-inline-flex tw-items-center",
        className,
      )}
    >
      {children} <IconExternalLink size={16} className="tw-ml-1" />
    </a>
  );
}
