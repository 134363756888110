import * as React from "react";
import type { HTMLAttributes } from "react";
import { cn } from "../../utils";

export const InputErrorMessage = React.forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  return (
    <p
      ref={ref}
      className={cn(
        "tw-mb-0 tw-text-sm tw-font-medium tw-text-red-600 dark:tw-text-red-900",
        className,
      )}
      {...props}
    />
  );
});
