import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "cva";
import type { FC, HTMLAttributes } from "react";
import { cn } from "../../utils";

const stack = cva("tw-flex", {
  variants: {
    direction: {
      vertical: "tw-flex-col tw-items-start",
      horizontal: "tw-flex-row tw-items-start",
    },
    spacing: {
      0: "tw-gap-0",
      1: "tw-gap-1",
      2: "tw-gap-2",
      3: "tw-gap-3",
      4: "tw-gap-4",
      5: "tw-gap-5",
      6: "tw-gap-6",
      7: "tw-gap-7",
      8: "tw-gap-8",
      9: "tw-gap-8",
      10: "tw-gap-8",
      12: "tw-gap-12",
      14: "tw-gap-14",
      16: "tw-gap-16",
    },
  },
  defaultVariants: {
    direction: "vertical",
    spacing: 0,
  },
});

export interface StackProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof stack> {
  asChild?: boolean;
}

export const Stack: FC<StackProps> = ({
  className,
  direction,
  spacing,
  asChild,
  ...props
}) => {
  const Comp = asChild ? Slot : "div";
  return (
    <Comp className={cn(stack({ direction, spacing }), className)} {...props} />
  );
};

Stack.displayName = "Stack";

export const HStack: FC<Omit<StackProps, "direction">> = (props) => (
  <Stack direction="horizontal" {...props} />
);

HStack.displayName = "HStack";

export const VStack: FC<Omit<StackProps, "direction">> = (props) => (
  <Stack direction="vertical" {...props} />
);

VStack.displayName = "HStack";
