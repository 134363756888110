import { useContext } from "react";
import { createStubbedContext } from "../../utils";

export type AlertContextValue = {
  variant: "error" | "success" | "warning" | "info";
  dismissible?: boolean;
};

export const AlertContext = createStubbedContext<AlertContextValue>({
  variant: "info",
});

export const useAlertContext = () => {
  return useContext(AlertContext);
};
