import { Tooltip, TooltipContent, TooltipTrigger } from "../../overlay/Tooltip";
import { Badge } from "../Badge";

export const timeSince = function (date: string | Date) {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "Year";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "Month";
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = "Day";
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "Hour";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "Minute";
          } else {
            interval = seconds;
            intervalType = "Second";
          }
        }
      }
    }
  }

  if (intervalType === "second" && interval < 5) {
    return "Just Now";
  }

  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }
  return interval + " " + intervalType + " Ago";
};

export const Timestamp = ({ of }: { of: string | Date }) => {
  const asDate = new Date(of);
  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge className="hover:tw-bg-zinc-100 hover:tw-ring-zinc-800/20 tw-cursor-pointer tw-z-[1] tw-relative">
          {timeSince(asDate)}
        </Badge>
      </TooltipTrigger>
      <TooltipContent className="tw-z-[1]">
        <dl className="tw-divide-y tw-divide-sky-700 tw-mb-0">
          <div className="tw-flex tw-content-center tw-justify-between tw-gap-2 tw-py-1">
            <dt className="tw-font-medium tw-text-sky-200">ISO</dt>
            <dd className="tw-text-sky-300 tw-mb-0">{asDate.toISOString()}</dd>
          </div>
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-1">
            <dt className="tw-font-medium tw-text-sky-200">Local</dt>
            <dd className="tw-text-sky-300 tw-mb-0">
              {asDate.toLocaleString()}
            </dd>
          </div>
        </dl>
      </TooltipContent>
    </Tooltip>
  );
};

Badge.displayName = "Badge";
