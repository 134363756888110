import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import type { VariantProps} from "cva";
import { cva } from "cva";
import { cn } from "../../utils";

const TooltipProvider = TooltipPrimitive.TooltipProvider;

const Tooltip = (props: TooltipPrimitive.TooltipProps) => {
  return <TooltipPrimitive.Root delayDuration={0} {...props} />;
};

Tooltip.defaultName = TooltipPrimitive.Root.displayName;

const TooltipTrigger = TooltipPrimitive.TooltipTrigger;

const tooltipContentStyles = cva(
  "tw-font-medium tw-rounded-lg tw-px-2 tw-py-1",
  {
    variants: {
      colorScheme: {
        indigo: "tw-bg-indigo-900 tw-text-indigo-100",
        red: "tw-bg-red-900 tw-text-red-100",
        slate: "tw-bg-slate-900 tw-text-slate-100",
        green: "tw-bg-green-900 tw-text-green-100",
        yellow: "tw-bg-yellow-900 tw-text-yellow-100",
        blue: "tw-bg-blue-900 tw-text-blue-100",
        pink: "tw-bg-pink-900 tw-text-pink-100",
        purple: "tw-bg-purple-900 tw-text-purple-100",
        teal: "tw-bg-teal-900 tw-text-teal-100",
        sky: "tw-bg-sky-900 tw-text-sky-100",
        zinc: "tw-bg-zinc-900 tw-text-zinc-100",
      },
      size: {
        md: "tw-text-xs",
        lg: "tw-text-sm",
        xl: "tw-text-md",
      },
    },
    defaultVariants: {
      size: "md",
      colorScheme: "sky",
    },
  },
);

const tooltipArrowStyles = cva("", {
  variants: {
    colorScheme: {
      indigo: "tw-fill-indigo-900",
      red: "tw-fill-red-900",
      slate: "tw-fill-slate-900",
      green: "tw-fill-green-900",
      yellow: "tw-fill-yellow-900",
      blue: "tw-fill-blue-900",
      pink: "tw-fill-pink-900",
      purple: "tw-fill-purple-900",
      teal: "tw-fill-teal-900",
      sky: "tw-fill-sky-900",
      zinc: "tw-fill-zinc-900",
    },
  },
  defaultVariants: {
    colorScheme: "sky",
  },
});

export interface TooltipContentProps
  extends TooltipPrimitive.TooltipContentProps,
    VariantProps<typeof tooltipContentStyles> {}

const TooltipContent = ({
  colorScheme,
  size,
  className,
  children,
  ...other
}: TooltipContentProps) => {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        className={cn(tooltipContentStyles({ colorScheme, size }), className)}
        {...other}
      >
        {children}
        <TooltipPrimitive.Arrow
          className={cn(tooltipArrowStyles({ colorScheme }))}
        />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  );
};

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent };
