import type { LoaderArgs } from "~/util/types";
import { data } from "@remix-run/cloudflare";
import { redirect } from "@remix-run/server-runtime";
import { createKratosClient, getKratosSession } from "~/services/kratos.server";
import { getSession } from "~/services/session.server";
import { userPermissions } from "~/services/userPermissions.server";

export async function loader({ context, request }: LoaderArgs) {
  const kratos = createKratosClient(context);
  const auth = await getKratosSession(request, kratos);
  if (!auth?.active) {
    return redirect("/sign-in");
  }
  const permissions = await userPermissions(context.api);
  if (permissions.canReadRootAccounts) {
    return redirect("/tenants");
  }

  const session = await getSession(request, context.env.SESSION_SIGNING_KEY);

  const { accounts } = await context.api.listAccounts();
  const tenantId = accounts.find((a) => !a.parent_id)?.id;
  if (!tenantId) {
    throw data({
      error: "Your user is not associated with any RAD Security tenants.",
    });
  }

  // Redirect the user to the last active account, falling back to the first account
  const activeAccountId = session.get("activeAccountId");
  const initialAccountId =
    (activeAccountId
      ? accounts.find((a) => a.id === activeAccountId)?.id
      : null) ?? accounts.find((a) => a.parent_id)?.id;

  if (!initialAccountId) {
    session.unset("activeAccountId");
  } else {
    session.set("activeAccountId", initialAccountId);
  }

  return session.commitWithResponse(
    redirect(
      initialAccountId
        ? `/${tenantId}/accounts/${initialAccountId}`
        : `/${tenantId}/settings/accounts`,
    ),
  );
}

// This index route serves only to redirect the user to the dashboard or
// sign-in page. It doesn't ever render a page -- if we wanted to add a splash
// page before sign-in, this would be the place.
export default function Index() {
  return <></>;
}
