import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown as IconChevronDown } from "lucide-react";
import * as React from "react";
import { cn } from "../../utils";

const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Root
    ref={ref}
    className={cn(
      "tw-rounded-md tw-overflow-hidden tw-border tw-border-zinc-300 tw-divide-y tw-divide-zinc-300",
      className,
    )}
    {...props}
  />
));

Accordion.displayName = "Accordion";

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn("", className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="tw-flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "tw-flex tw-py-4 tw-px-6 tw-flex-1 tw-items-center tw-justify-between tw-font-medium tw-transition-all hover:tw-bg-zinc-50 hover:bg-blend-darken [&[data-state=open]>svg]:tw-rotate-180",
        className,
      )}
      {...props}
    >
      {children}
      <IconChevronDown className="tw-h-4 tw-w-4 tw-shrink-0 tw-transition-transform tw-duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "tw-overflow-hidden tw-px-6 tw-pt-2 tw-text-sm tw-text-zinc-700 tw-transition-all data-[state=closed]:tw-animate-accordion-up data-[state=open]:tw-animate-accordion-down",
      className,
    )}
    {...props}
  >
    <div className="tw-pb-4 tw-pt-0">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
