import type { VariantProps} from "cva";
import { cva } from "cva";
import * as React from "react";
import { useId } from "react";
import { HStack } from "../../core/Stack";
import { cn } from "../../utils";
import { Label } from "../Label";

const radioStyles = cva("tw-border-zinc-300", {
  variants: {
    size: {
      sm: "tw-h-3 tw-w-3",
      md: "tw-h-4 tw-w-4",
      lg: "tw-h-5 tw-w-5",
    },
    colorScheme: {
      indigo: "tw-text-indigo-600 focus:tw-ring-indigo-600",
      zinc: "tw-text-zinc-700 focus:tw-ring-zinc-700",
    },
  },
  defaultVariants: {
    size: "md",
    colorScheme: "zinc",
  },
});

export interface RadioInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">,
    VariantProps<typeof radioStyles> {}

export const Radio = React.forwardRef<HTMLInputElement, RadioInputProps>(
  ({ className, size, ...props }, ref) => {
    return (
      <input
        type="radio"
        className={cn(radioStyles({ size }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);

export type RadioWithLabelProps = Omit<RadioInputProps, "id"> & {
  children: React.ReactNode;
};

export const RadioWithLabel = React.forwardRef<
  HTMLInputElement,
  RadioInputProps
>(({ children, size, ...other }, ref) => {
  const id = useId();
  return (
    <HStack spacing={2} className="tw-items-center">
      <Radio size={size} {...other} id={id} ref={ref} />
      <Label htmlFor={id} size={size}>
        {children}
      </Label>
    </HStack>
  );
});
