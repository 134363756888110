import type { VariantProps} from "cva";
import { cva } from "cva";
import type React from "react";
import { cn } from "../../utils";

const spinnerStyles = cva("tw-animate-spin", {
  variants: {
    size: {
      xs: "tw-h-3 tw-w-3",
      sm: "tw-h-4 tw-w-4",
      md: "tw-h-5 tw-w-5",
      lg: "tw-h-6 tw-w-6",
      xl: "tw-h-7 tw-w-7",
    },
    colorScheme: {
      blue: ["tw-text-blue-600"],
      green: ["tw-text-green-600"],
      indigo: ["tw-text-indigo-600"],
      red: ["tw-text-red-600"],
      slate: ["tw-text-slate-600"],
      teal: ["tw-text-teal-600"],
      white: ["tw-text-white"],
      yellow: ["tw-text-yellow-600"],
      zinc: ["tw-text-zinc-600"],
    },
  },
  defaultVariants: {
    size: "md",
    colorScheme: "white",
  },
});

export interface SpinnerProps
  extends React.HTMLAttributes<SVGElement>,
    VariantProps<typeof spinnerStyles> {}

export const Spinner = ({
  size,
  colorScheme,
  className,
  ...other
}: SpinnerProps) => {
  return (
    <svg
      className={cn(spinnerStyles({ size, colorScheme }), className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...other}
    >
      <circle
        className="tw-opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="tw-opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
