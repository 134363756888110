import { format } from "date-fns";
import { CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react";
import type * as React from "react";
import type { DateRange, PropsRange } from "react-day-picker";
import { DayPicker } from "react-day-picker";
import { Button, button as buttonVariants } from "../../core/Button";
import { Popover, PopoverContent, PopoverTrigger } from "../../overlay/Popover";
import { cn } from "../../utils";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

export function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months:
          "tw-flex tw-flex-col sm:tw-flex-row tw-relative sm:tw-space-x-4 sm:tw-space-y-0",
        month: "tw-space-y-4",
        month_caption:
          "tw-flex tw-justify-center tw-pt-1 tw-relative tw-items-center tw-ml-8 tw-mr-8",
        caption_label: "tw-text-sm tw-font-medium",
        nav: "tw-space-x-1 tw-flex tw-items-center tw-justify-between tw-absolute tw-w-full",
        button_previous: cn(
          buttonVariants({ variant: "primary" }),
          "tw-h-7 tw-w-7 tw-p-0 tw-opacity-90 hover:tw-opacity-100",
          "tw-left-1",
        ),
        button_next: cn(
          buttonVariants({ variant: "primary" }),
          "tw-h-7 tw-w-7 tw-p-0 tw-opacity-90 hover:tw-opacity-100",
          "tw-right-1",
        ),
        month_grid: "tw-w-full tw-border-collapse tw-space-y-1",
        weekdays: "tw-flex",
        weekday:
          "tw-text-zinc-500 tw-rounded-md tw-w-9 tw-font-normal tw-text-[0.8rem] dark:tw-text-zinc-400",
        week: "tw-flex tw-w-full tw-mt-2",
        day: "tw-h-9 tw-w-9 tw-text-center tw-text-sm tw-p-0 tw-relative [&:has([aria-selected].range-end)]:tw-rounded-r-md [&:has([aria-selected].outside)]:tw-bg-zinc-100/50 [&:has([aria-selected])]:tw-bg-zinc-100 first:[&:has([aria-selected])]:tw-rounded-l-md last:[&:has([aria-selected])]:tw-rounded-r-md focus-within:tw-relative focus-within:tw-z-20 dark:[&:has([aria-selected].outside)]:tw-bg-zinc-800/50 dark:[&:has([aria-selected])]:tw-bg-zinc-800",
        day_button: cn(
          buttonVariants({ variant: "tertiary" }),
          "tw-h-9 tw-w-9 tw-p-0 tw-font-normal tw-bg-transparent aria-selected:tw-opacity-100",
        ),
        range_end: cn(
          "range-end",
          buttonVariants({ variant: "primary" }),
          "!tw-text-white tw-p-0 [&>button]:tw-text-white [&>button]:hover:tw-text-zinc-600",
        ),
        range_middle:
          "aria-selected:tw-bg-zinc-100 aria-selected:tw-text-zinc-900 dark:aria-selected:tw-bg-zinc-800 dark:aria-selected:tw-text-zinc-50",
        range_start: cn(
          "range-start [&>button]:tw-text-white [&>button]:hover:tw-text-zinc-600",
          buttonVariants({ variant: "primary" }),
          "!tw-text-white tw-p-0",
        ),
        selected:
          "tw-bg-zinc-900 tw-text-zinc-50 hover:tw-bg-zinc-900 hover:tw-text-zinc-50 focus:tw-bg-zinc-900 focus:tw-text-zinc-50 dark:tw-bg-zinc-50 dark:tw-text-zinc-900 dark:tw-hover:bg-zinc-50 dark:hover:tw-text-zinc-900 dark:focus:tw-bg-zinc-50 dark:focus:tw-text-zinc-900",
        today:
          "tw-bg-zinc-100 tw-rounded tw-text-zinc-900 dark:tw-bg-zinc-800 dark:tw-text-zinc-50",
        outside:
          "outside tw-text-zinc-500 tw-opacity-50 aria-selected:tw-bg-zinc-100/50 aria-selected:tw-text-zinc-500 aria-selected:tw-opacity-30 dark:tw-text-zinc-400 dark:aria-selected:tw-bg-zinc-800/50 dark:aria-selected:tw-text-zinc-400",
        disabled: "tw-text-zinc-500 tw-opacity-50 dark:tw-text-zinc-400",

        hidden: "tw-invisible",
        ...classNames,
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === "left") {
            return <ChevronLeft {...props} className="tw-h-4 tw-w-4" />;
          }
          return <ChevronRight {...props} className="tw-h-4 tw-w-4" />;
        },
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export function CalendarInput({
  value,
  dateFormat = "PPP",
  onChange,
  ...others
}: Omit<CalendarProps, "selected" | "onSelect" | "mode"> & {
  value?: Date;
  dateFormat?: string;
  onChange: (date: Date | undefined) => void;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"tertiary"}
          className={cn(
            "tw-w-[220px] tw-justify-start tw-text-left tw-font-normal",
            !value && "tw-text-zinc-500",
          )}
        >
          <CalendarIcon className="tw-mr-2 tw-h-4 tw-w-4" />
          {value ? format(value, dateFormat) : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="tw-flex tw-w-auto tw-flex-col tw-space-y-2 tw-p-2">
        <Calendar
          mode="single"
          selected={value}
          onSelect={onChange}
          {...others}
        />
      </PopoverContent>
    </Popover>
  );
}

export function CalendarRangeInput({
  value,
  onChange,
  dateFormat = "LLL dd, y",
  ...others
}: Omit<PropsRange, "selected" | "onSelect" | "mode"> & {
  value?: DateRange;
  dateFormat?: string;
  onChange: (date: DateRange | undefined) => void;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"tertiary"}
          className={cn(
            "tw-w-[240px] tw-justify-start tw-text-left tw-font-normal",
            !value && "tw-text-zinc-500",
          )}
        >
          <CalendarIcon className="tw-mr-2 tw-h-4 tw-w-4" />
          {value?.from ? (
            value.to ? (
              <>
                {format(value.from, dateFormat)} -{" "}
                {format(value.to, dateFormat)}
              </>
            ) : (
              format(value.from, dateFormat)
            )
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="tw-flex tw-w-auto tw-flex-col tw-space-y-2 tw-p-2">
        <Calendar
          mode="range"
          selected={value}
          onSelect={onChange}
          {...others}
        />
      </PopoverContent>
    </Popover>
  );
}
