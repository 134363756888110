import * as SwitchPrimitives from "@radix-ui/react-switch";
import type { VariantProps} from "cva";
import { cva } from "cva";
import * as React from "react";
import { useId } from "react";
import { HStack } from "../../core/Stack";
import { cn } from "../../utils";
import { Label } from "../Label";

const switchStyles = cva(
  "tw-peer tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-white disabled:tw-cursor-not-allowed disabled:tw-opacity-50",
  {
    variants: {
      colorScheme: {
        slate:
          "focus-visible:tw-ring-slate-950 data-[state=checked]:tw-bg-slate-900 data-[state=unchecked]:tw-bg-slate-200 dark:tw-focus-visible:tw-ring-slate-300 dark:tw-focus-visible:tw-ring-offset-slate-950 dark:tw-data-[state=checked]:tw-bg-slate-50 dark:tw-data-[state=unchecked]:tw-bg-slate-800",
        indigo:
          "focus-visible:tw-ring-indigo-600 data-[state=checked]:tw-bg-indigo-600 data-[state=unchecked]:tw-bg-indigo-200 dark:tw-focus-visible:tw-ring-indigo-300 dark:tw-focus-visible:tw-ring-offset-indigo-950 dark:tw-data-[state=checked]:tw-bg-indigo-50 dark:tw-data-[state=unchecked]:tw-bg-indigo-800",
        zinc: "focus-visible:tw-ring-zinc-800 data-[state=checked]:tw-bg-zinc-800 data-[state=unchecked]:tw-bg-zinc-200 dark:tw-focus-visible:tw-ring-zinc-300 dark:tw-focus-visible:tw-ring-offset-zinc-950 dark:tw-data-[state=checked]:tw-bg-zinc-50 dark:tw-data-[state=unchecked]:tw-bg-zinc-800",
      },
      size: {
        sm: "tw-h-[20px] tw-w-[40px]",
        md: "tw-h-[24px] tw-w-[44px]",
        lg: "tw-h-[28px] tw-w-[48px]",
      },
    },
    defaultVariants: {
      colorScheme: "zinc",
      size: "md",
    },
  },
);

const switchInnerStyles = cva(
  "tw-pointer-events-none tw-block tw-rounded-full tw-bg-white tw-shadow-lg tw-ring-0 tw-transition-transform data-[state=checked]:tw-translate-x-5 data-[state=unchecked]:tw-translate-x-0 dark:tw-bg-slate-950",
  {
    variants: {
      size: {
        sm: "tw-h-4 tw-w-4",
        md: "tw-h-5 tw-w-5",
        lg: "tw-h-6 tw-w-6",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
);

export interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    VariantProps<typeof switchStyles> {}

export const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, colorScheme, size, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(switchStyles({ colorScheme, size }), className)}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb className={cn(switchInnerStyles({ size }))} />
  </SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export const SwitchWithLabel = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ children, size, ...other }, ref) => {
  const id = useId();
  return (
    <HStack spacing={2} className="tw-items-center">
      <Switch size={size} {...other} id={id} ref={ref} />
      <Label size={size} htmlFor={id}>
        {children}
      </Label>
    </HStack>
  );
});
