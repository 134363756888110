import { Search as IconSearch } from "lucide-react";
import { forwardRef, useId } from "react";
import type { InputProps } from "../../../forms/Input";
import { Input } from "../../../forms/Input";
import { Label } from "../../../forms/Label";
import { cn } from "../../../utils";

export const FacetSearch = forwardRef<HTMLInputElement, InputProps>(
  ({ className, id, ...props }: InputProps, ref) => {
    const internalId = useId();
    id = id ?? internalId;
    return (
      <>
        <Label className="tw-sr-only" htmlFor={id}>
          Search query
        </Label>
        <Input
          id={id}
          leftElement={<IconSearch size={16} className="tw-text-zinc-400" />}
          className={cn("tw-pl-7", className)}
          {...props}
          ref={ref}
        />
      </>
    );
  },
);
