import type { VariantProps} from "cva";
import { cva } from "cva";
import {
  Check as IconCheck,
  ShipWheel as IconCircleFilled,
  Filter as IconFilter,
} from "lucide-react";
import * as React from "react";
import { Badge } from "../../../core/Badge";
import { Button } from "../../../core/Button";
import { Separator } from "../../../core/Seperator";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../../overlay/Command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../overlay/Popover";
import { cn } from "../../../utils";

const popoverContent = cva("tw-p-0", {
  variants: {
    size: {
      auto: "tw-w-auto",
      xs: "tw-w-[60px]",
      sm: "tw-w-[120px]",
      md: "tw-w-[240px]",
      lg: "tw-w-[360px]",
      xl: "tw-w-[480px]",
      "2xl": "tw-w-[600px]",
    },
  },
  defaultVariants: {
    size: "auto",
  },
});

type DataTableFilterOption =
  | string
  | {
      label: React.ReactNode;
      value: string;
      icon?: React.ComponentType<{
        className?: string;
      }>;
    };

interface DataTableCommon {
  title?: string;
  options: DataTableFilterOption[];
  selectedOptionsBeforeCountBadge?: number;
  popoverContentVariants?: VariantProps<typeof popoverContent>;
}

interface DataTableMultiFacetedFilter extends DataTableCommon {
  value: Set<string>;
  onChange: (items: Set<string>) => void;
  allowSelectAll?: boolean;
}

interface DataTableSingleFacetedFilter extends DataTableCommon {
  value?: string;
  onChange: (item: string | undefined) => void;
}

export function FacetCommandSingle({
  value,
  title,
  options,
  selectedOptionsBeforeCountBadge,
  onChange,
  popoverContentVariants,
}: DataTableSingleFacetedFilter) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <FacetTrigger
          title={title}
          options={options}
          selectedOptionsBeforeCountBadge={selectedOptionsBeforeCountBadge}
          value={value}
        />
      </PopoverTrigger>
      <PopoverContent
        className={popoverContent(popoverContentVariants)}
        align="start"
      >
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isStringOption = typeof option === "string";
                const optionValue = getOptionValue(option);
                const optionLabel = getOptionLabel(option);
                const isSelected = value === optionValue;
                return (
                  <CommandItem
                    key={optionValue}
                    onSelect={() => {
                      if (value === optionValue) {
                        onChange(undefined);
                      } else {
                        onChange(optionValue);
                      }
                    }}
                  >
                    <div
                      className={cn(
                        "tw-mr-2 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-zinc-700",
                        isSelected
                          ? "tw-text-zinc-700"
                          : "tw-opacity-50 [&_svg]:tw-invisible",
                      )}
                    >
                      <IconCircleFilled className={cn("tw-h-3 tw-w-3")} />
                    </div>
                    {!isStringOption && option.icon && (
                      <option.icon className="tw-mr-2 tw-h-4 tw-w-4 tw-text-muted-foreground" />
                    )}
                    <span>{optionLabel}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {value && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onChange(undefined)}
                    className="tw-justify-center tw-text-center"
                  >
                    Clear filter
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export function FacetCommandMulti({
  title,
  selectedOptionsBeforeCountBadge,
  options,
  value,
  onChange,
  allowSelectAll = true,
  popoverContentVariants,
}: DataTableMultiFacetedFilter) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <FacetTrigger
          title={title}
          options={options}
          selectedOptionsBeforeCountBadge={selectedOptionsBeforeCountBadge}
          value={value}
        />
      </PopoverTrigger>
      <PopoverContent
        className={popoverContent(popoverContentVariants)}
        align="start"
      >
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isStringOption = typeof option === "string";
                const optionValue = getOptionValue(option);
                const optionLabel = getOptionLabel(option);
                const isSelected = value.has(optionValue);
                return (
                  <CommandItem
                    key={optionValue}
                    onSelect={() => {
                      const nextValue = new Set(value);
                      if (nextValue.has(optionValue)) {
                        nextValue.delete(optionValue);
                      } else {
                        nextValue.add(optionValue);
                      }
                      onChange(nextValue);
                    }}
                  >
                    <div
                      className={cn(
                        "tw-mr-2 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-border tw-rounded-sm tw-border-zinc-700",
                        isSelected
                          ? "tw-bg-zinc-700 tw-text-white"
                          : "tw-opacity-50 [&_svg]:tw-invisible",
                      )}
                    >
                      <IconCheck className={cn("tw-h-4 tw-w-4")} />
                    </div>
                    {!isStringOption && option.icon && (
                      <option.icon className="tw-mr-2 tw-h-4 tw-w-4 tw-text-muted-foreground" />
                    )}
                    <span>{optionLabel}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {(allowSelectAll || value.size > 0) && <CommandSeparator />}
            {value.size > 0 && (
              <CommandGroup>
                <CommandItem
                  onSelect={() => onChange(new Set())}
                  className="tw-justify-center tw-text-center"
                >
                  Clear filters
                </CommandItem>
              </CommandGroup>
            )}
            {allowSelectAll && (
              <CommandGroup>
                <CommandItem
                  onSelect={() =>
                    onChange(new Set(options.map(getOptionValue)))
                  }
                  className="tw-justify-center tw-text-center"
                >
                  Select all
                </CommandItem>
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

const FacetTrigger = React.forwardRef<
  HTMLButtonElement,
  Omit<DataTableSingleFacetedFilter | DataTableMultiFacetedFilter, "onChange">
>(
  (
    { title, value, selectedOptionsBeforeCountBadge = 3, options, ...other },
    ref,
  ) => {
    const selectedValues = new Set(typeof value === "string" ? [value] : value);
    const isAllSelected = selectedValues.size === options.length;
    const isCountBadge = selectedValues.size >= selectedOptionsBeforeCountBadge;
    return (
      <Button
        variant="bordered"
        colorScheme="zinc"
        size="sm"
        className="tw-h-9 tw-border-zinc-400 tw-font-medium tw-text-zinc-700 tw-text-sm tw-border-dashed"
        {...other}
        ref={ref}
      >
        <IconFilter className="tw-mr-2 tw-h-3 tw-w-3" />
        {title}
        {selectedValues?.size > 0 && (
          <>
            <Separator orientation="vertical" className="tw-mx-2 tw-h-4" />
            <div className="tw-space-x-1">
              {isAllSelected ? (
                <Badge
                  pill={false}
                  size="sm"
                  uppercase={false}
                  variant="solid"
                  colorScheme="zinc"
                >
                  All selected
                </Badge>
              ) : isCountBadge ? (
                <Badge
                  pill={false}
                  size="sm"
                  uppercase={false}
                  variant="solid"
                  colorScheme="zinc"
                >
                  {selectedValues.size} selected
                </Badge>
              ) : (
                options
                  .filter((option) =>
                    selectedValues.has(getOptionValue(option)),
                  )
                  .map((option) => (
                    <Badge
                      pill={false}
                      size="sm"
                      uppercase={false}
                      variant="solid"
                      colorScheme="zinc"
                      key={getOptionValue(option)}
                    >
                      {getOptionLabel(option)}
                    </Badge>
                  ))
              )}
            </div>
          </>
        )}
      </Button>
    );
  },
);

function getOptionValue(option: DataTableFilterOption) {
  return typeof option === "string" ? option : option.value;
}
function getOptionLabel(option: DataTableFilterOption) {
  return typeof option === "string" ? option : option.label;
}
