import type React from "react";
import {
  type Toast,
  toast as rhtToast,
  useToaster,
} from "react-hot-toast/headless";
import { cn } from "../../utils";
import type {
  AlertProps} from "../Alert";
import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "../Alert";

type ToastProps = {
  variant: AlertProps["variant"];
  title?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
};

const toastOffsetOptions = {
  reverseOrder: false,
  gutter: 8,
  defaultPosition: "bottom-center",
} as const;

export const Toaster = () => {
  const {
    toasts,
    handlers: { startPause, endPause },
  } = useToaster();

  return (
    <div
      className="tw-fixed tw-bottom-2 tw-left-1/2 -tw-translate-x-1/2 tw-w-[450px] tw-max-w-full"
      style={
        // Don't render on the server, as this can trigger CSP errors
        toasts[0]?.height
          ? {
              // @ts-expect-error CSS variables aren't part of the React CSSOM
              "--tw-translate-y": `${-(toasts[0].height ?? 0)}px`,
            }
          : {}
      }
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => (
        <SliceOfToast key={toast.id} toast={toast} />
      ))}
    </div>
  );
};

function SliceOfToast({ toast }: { toast: Toast }) {
  const {
    handlers: { calculateOffset, updateHeight },
  } = useToaster();
  const offset = calculateOffset(toast, toastOffsetOptions);

  return (
    <div
      ref={(el) => {
        if (el && typeof toast.height !== "number") {
          const { height } = el.getBoundingClientRect();
          updateHeight(toast.id, height);
        }
      }}
      className={cn(
        "tw-absolute tw-duration-200 tw-w-[450px] tw-mx-auto",
        toast.visible ? "tw-opacity-100" : "tw-opacity-0",
      )}
      style={{ transform: `translateY(${-offset}px)` }}
      {...toast.ariaProps}
    >
      {typeof toast.message === "function"
        ? toast.message(toast)
        : toast.message}
    </div>
  );
}

function alertToast({ variant, title, description, actions }: ToastProps) {
  rhtToast.custom((t) => (
    <Alert
      variant={variant}
      className={
        t.visible
          ? "tw-duration-200 tw-animate-in tw-fade-in"
          : "tw-duration-200 tw-animate-out tw-fade-out"
      }
    >
      <AlertIcon />
      {title && <AlertTitle>{title}</AlertTitle>}
      {description && <AlertDescription>{description}</AlertDescription>}
      {actions && <AlertActions>{actions}</AlertActions>}
    </Alert>
  ));
}

export const toast = Object.assign(alertToast, {
  error: (props: Omit<ToastProps, "variant">) => {
    alertToast({ variant: "error", ...props });
  },
  success: (props: Omit<ToastProps, "variant">) => {
    alertToast({ variant: "success", ...props });
  },
  warning: (props: Omit<ToastProps, "variant">) => {
    alertToast({ variant: "warning", ...props });
  },
  info: (props: Omit<ToastProps, "variant">) => {
    alertToast({ variant: "info", ...props });
  },
  custom: rhtToast.custom,
});

export function useToast() {
  return toast;
}
