import * as React from "react";
import type { HTMLAttributes } from "react";
import type { StackProps } from "../../core/Stack";
import { VStack } from "../../core/Stack";
import { Label } from "../Label";
import type { RadioWithLabelProps } from "../Radio";
import { RadioWithLabel } from "../Radio";
import type { RadioGroupContextValue } from "./RadioGroupContext";
import { RadioGroupContext, useRadioGroupContext } from "./RadioGroupContext";

export type RadioGroupProps = React.HTMLAttributes<HTMLFieldSetElement> &
  Pick<StackProps, "spacing"> &
  RadioGroupContextValue;

const RadioGroup = React.forwardRef<HTMLFieldSetElement, RadioGroupProps>(
  (
    {
      name,
      value,
      onValueChange,
      required,
      disabled,
      className,
      spacing = 2,
      ...props
    },
    ref,
  ) => {
    return (
      <RadioGroupContext.Provider
        value={{
          name,
          value,
          onValueChange,
          disabled: disabled,
          required: required,
        }}
      >
        <VStack asChild={true} spacing={spacing} className={className}>
          <fieldset {...props} ref={ref} />
        </VStack>
      </RadioGroupContext.Provider>
    );
  },
);

RadioGroup.displayName = "RadioGroup";

const RadioGroupItem = (
  props: Omit<RadioWithLabelProps, "name" | "checked" | "defaultChecked">,
) => {
  const context = useRadioGroupContext();
  return (
    <RadioWithLabel
      name={context.name}
      disabled={context.disabled || props.disabled}
      required={context.required || props.required}
      checked={context.value === props.value}
      onChange={() => {
        context.onValueChange(props.value as string);
      }}
      {...props}
    />
  );
};

RadioGroupItem.displayName = "RadioGroupItem";

const RadioGroupLegend = (props: HTMLAttributes<HTMLLegendElement>) => {
  return (
    <Label asChild={true} className="tw-mb-2 tw-float-none">
      <legend {...props}></legend>
    </Label>
  );
};

RadioGroupLegend.displayName = "RadioGroupLegend";

export { RadioGroup, RadioGroupLegend, RadioGroupItem };
