import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import { BackendError } from "./errors/BackendError";
import { GenericError } from "./errors/GenericError";
import { RouteError } from "./errors/RouteError";
import { UnknownProblem } from "./errors/UnknownProblem";

export function ErrorMessage() {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    return <RouteError error={error} />;
  }

  if (error instanceof Error) {
    if (typeof error?.message === "string" && error.message.startsWith("{")) {
      try {
        const errorDetail = JSON.parse(error.message);
        return <BackendError detail={errorDetail} />;
      } catch (_) {
        return <GenericError error={error} />;
      }
    }
    return <GenericError error={error} />;
  }
  return <UnknownProblem />;
}
