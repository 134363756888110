import { createContext } from "react";
import type { ClassNameValue} from "tailwind-merge";
import { extendTailwindMerge } from "tailwind-merge";

const configuredTailwindMerge = extendTailwindMerge({
  prefix: "tw-",
});

export function cn(...inputs: ClassNameValue[]) {
  return configuredTailwindMerge(inputs);
}

export function createStubbedContext<T>(partialDefault: Partial<T> = {}) {
  const defaultValue = new Proxy(
    {},
    {
      get: (_, p, r) => {
        if (Reflect.has(partialDefault, p))
          return Reflect.get(partialDefault, p, r);
        throw new Error(
          `Cannot read ${String(
            p,
          )} without being wrapped in a context provider`,
        );
      },
    },
  ) as T;
  return createContext(defaultValue);
}
