import { useContext } from "react";
import { createStubbedContext } from "../../utils";

export type RadioGroupContextValue = {
  name: string;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  onValueChange(value: string): void;
};

export const RadioGroupContext = createStubbedContext<RadioGroupContextValue>(
  {},
);

export const useRadioGroupContext = () => {
  return useContext(RadioGroupContext);
};
