import * as LabelPrimitive from "@radix-ui/react-label";
import { type VariantProps, cva } from "cva";
import * as React from "react";
import { cn } from "../../utils";

const labelVariants = cva(
  "tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-zinc-900 peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70",
  {
    variants: {
      size: {
        xs: "tw-text-xs",
        sm: "tw-text-sm",
        md: "tw-text-md",
        lg: "tw-text-lg",
        xl: "tw-text-xl",
      },
    },
  },
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, size, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({ size }), className)}
    {...props}
  />
));

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
