import type { VariantProps} from "cva";
import { cva } from "cva";
import * as React from "react";
import { useId } from "react";
import { VStack } from "../../core/Stack";
import { cn } from "../../utils";
import { Label } from "../Label";

const textareaStyles = cva(
  "tw-flex tw-w-full tw-rounded-md tw-border-0 tw-shadow-sm tw-ring-1 tw-ring-inset focus-visible:tw-ring-2 focus-visible:tw-ring-inset disabled:tw-cursor-not-allowed disabled:tw-opacity-50 dark:tw-border-zinc-800 dark:tw-border-zinc-800 dark:tw-bg-zinc-950 dark:tw-ring-offset-zinc-950 dark:tw-placeholder:tw-text-zinc-400 dark:tw-focus-visible:tw-ring-zinc-300",
  {
    variants: {
      colorScheme: {
        slate: "focus-visible:tw-ring-slate-950",
        indigo: "focus-visible:tw-ring-indigo-600",
        zinc: "focus-visible:tw-ring-zinc-700",
      },
      isInvalid: {
        true: "tw-ring-red-600 focus-visible:tw-ring-red-600",
        false: "tw-ring-zinc-300",
      },
      size: {
        sm: [
          "tw-min-h-[60px]",
          "tw-px-2",
          "tw-py-1",
          "tw-text-sm",
          "gap-x-1.5",
        ],
        md: [
          "tw-min-h-[80px]",
          "tw-px-2.5",
          "tw-py-1.5",
          "tw-text-sm",
          "gap-x-1.5",
        ],
        lg: [
          "tw-min-h-[100px]",
          "tw-px-3",
          "tw-py-2",
          "tw-text-sm",
          "gap-x-1.5",
        ],
      },
    },
    defaultVariants: {
      colorScheme: "zinc",
      size: "md",
      isInvalid: false,
    },
  },
);

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaStyles> {}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, colorScheme, isInvalid, size, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          textareaStyles({ colorScheme, size, isInvalid }),
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Textarea.displayName = "Textarea";

export const TextareaWithLabel = React.forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(({ children, size, ...props }, ref) => {
  const id = useId();
  return (
    <VStack spacing={1}>
      <Label size={size} htmlFor={id}>
        {children}
      </Label>
      <Textarea size={size} {...props} id={id} ref={ref} />
    </VStack>
  );
});

TextareaWithLabel.displayName = "TextareaWithLabel";
