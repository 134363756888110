import { cva } from "cva";
import type React from "react";
import { cloneElement, isValidElement } from "react";

const buttonIcon = cva("tw-inline-flex tw-shrink-0");

export const ButtonInnerIcon = (
  props: React.HTMLAttributes<HTMLSpanElement>,
) => {
  const { children, className, ...rest } = props;

  const _children = isValidElement(children)
    ?  
      cloneElement<any>(children, {
        "aria-hidden": true,
        focusable: false,
      })
    : children;

  return (
    <span className={buttonIcon({ className })} {...rest}>
      {_children}
    </span>
  );
};
