import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "cva";
import React, { isValidElement } from "react";
import { cn } from "../../utils";
import { Spinner } from "../Spinner";
import { ButtonInnerIcon } from "./button-inner-icon";

export const button = cva(
  "tw-rounded tw-font-sans tw-font-medium tw-items-center tw-inline-flex tw-justify-center",
  {
    variants: {
      variant: {
        primary: [
          "focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2",
        ],
        secondary: ["tw-ring-2", "tw-ring-inset"],
        tertiary: [],
        naked: [],
        bordered: ["tw-border tw-shadow-sm hover:tw-bg-zinc-50"],
      },
      colorScheme: {
        indigo: ["focus-visible:tw-outline-indigo-600"],
        red: ["focus-visible:tw-outline-red-600"],
        slate: ["focus-visible:tw-outline-slate-600"],
        green: ["focus-visible:tw-outline-green-600"],
        yellow: ["focus-visible:tw-outline-yellow-600"],
        blue: ["focus-visible:tw-outline-blue-600"],
        teal: ["focus-visible:tw-outline-teal-600"],
        zinc: ["focus-visible:tw-outline-zinc-600"],
      },
      size: {
        xs: ["tw-px-2", "tw-py-1", "tw-text-xs", "gap-x-1"],
        sm: ["tw-px-2", "tw-py-1.5", "tw-text-sm", "gap-x-1.5"],
        md: ["tw-px-2.5", "tw-py-2", "tw-text-sm", "gap-x-1.5"],
        lg: ["tw-px-3", "tw-py-2.5", "tw-text-sm", "gap-x-1.5"],
        xl: ["tw-px-3.5", "tw-py-3", "tw-text-sm", "gap-x-2"],
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        colorScheme: "indigo",
        className:
          "tw-bg-indigo-600 disabled:tw-bg-indigo-400 hover:tw-bg-indigo-500 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "indigo",
        className:
          "tw-ring-indigo-300 hover:tw-bg-indigo-50 tw-text-indigo-600",
      },
      {
        variant: "tertiary",
        colorScheme: "indigo",
        className: "tw-bg-indigo-100 hover:tw-bg-indigo-200 tw-text-indigo-600",
      },
      {
        variant: "naked",
        colorScheme: "indigo",
        className: "tw-bg-transparent hover:tw-bg-indigo-50 tw-text-indigo-600",
      },
      {
        variant: "primary",
        colorScheme: "slate",
        className:
          "tw-bg-slate-600 disabled:tw-bg-slate-300 hover:tw-bg-slate-500 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "slate",
        className: "tw-ring-slate-300 hover:tw-bg-slate-50 tw-text-slate-600",
      },
      {
        variant: "tertiary",
        colorScheme: "slate",
        className: "tw-bg-slate-100 hover:tw-bg-slate-200 tw-text-slate-600",
      },
      {
        variant: "naked",
        colorScheme: "slate",
        className: "tw-bg-transparent hover:tw-bg-slate-50 tw-text-slate-600",
      },
      {
        variant: "primary",
        colorScheme: "red",
        className:
          "tw-bg-red-600 disabled:tw-bg-red-300 hover:tw-bg-red-500 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "red",
        className: "tw-ring-red-300 hover:tw-bg-red-50 tw-text-red-600",
      },
      {
        variant: "tertiary",
        colorScheme: "red",
        className: "tw-bg-red-100 hover:tw-bg-red-100 tw-text-red-600",
      },
      {
        variant: "naked",
        colorScheme: "red",
        className: "tw-bg-transparent hover:tw-bg-red-50 tw-text-red-600",
      },
      {
        variant: "primary",
        colorScheme: "green",
        className:
          "tw-bg-green-600 disabled:tw-bg-green-300 hover:tw-bg-green-500 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "green",
        className: "tw-ring-green-300 hover:tw-bg-green-50 tw-text-green-600",
      },
      {
        variant: "tertiary",
        colorScheme: "green",
        className: "tw-bg-green-100 hover:tw-bg-green-100 tw-text-green-600",
      },
      {
        variant: "naked",
        colorScheme: "green",
        className: "tw-bg-transparent hover:tw-bg-green-50 tw-text-green-600",
      },
      {
        variant: "primary",
        colorScheme: "yellow",
        className:
          "tw-bg-yellow-600 disabled:tw-bg-yellow-300 hover:tw-bg-yellow-500 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "yellow",
        className:
          "tw-ring-yellow-300  hover:tw-bg-yellow-50 tw-text-yellow-600",
      },
      {
        variant: "tertiary",
        colorScheme: "yellow",
        className: "tw-bg-yellow-100 hover:tw-bg-yellow-100 tw-text-yellow-600",
      },
      {
        variant: "naked",
        colorScheme: "yellow",
        className: "tw-bg-transparent hover:tw-bg-yellow-50 tw-text-yellow-600",
      },
      {
        variant: "primary",
        colorScheme: "blue",
        className:
          "tw-bg-blue-600 disabled:tw-bg-blue-300 hover:tw-bg-blue-500 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "blue",
        className: "tw-ring-blue-300 hover:tw-bg-blue-50 tw-text-blue-600",
      },
      {
        variant: "tertiary",
        colorScheme: "blue",
        className: "tw-bg-blue-100 hover:tw-bg-blue-100 tw-text-blue-600",
      },
      {
        variant: "naked",
        colorScheme: "blue",
        className: "tw-bg-transparent hover:tw-bg-blue-50 tw-text-blue-600",
      },
      {
        variant: "primary",
        colorScheme: "zinc",
        className:
          "tw-bg-zinc-800 disabled:tw-bg-zinc-500 hover:tw-bg-zinc-700 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "zinc",
        className: "tw-ring-zinc-300 hover:tw-bg-zinc-50 tw-text-zinc-600",
      },
      {
        variant: "tertiary",
        colorScheme: "zinc",
        className: "tw-bg-zinc-100 hover:tw-bg-zinc-200 tw-text-zinc-600",
      },
      {
        variant: "naked",
        colorScheme: "zinc",
        className: "tw-bg-transparent hover:tw-bg-zinc-50 tw-text-zinc-600",
      },
      {
        variant: "primary",
        colorScheme: "teal",
        className:
          "tw-bg-teal-800 disabled:tw-bg-teal-500 hover:tw-bg-teal-700 tw-text-white",
      },
      {
        variant: "secondary",
        colorScheme: "teal",
        className: "tw-ring-teal-300 hover:tw-bg-teal-50 tw-text-teal-600",
      },
      {
        variant: "tertiary",
        colorScheme: "teal",
        className: "tw-bg-teal-100 hover:tw-bg-teal-200 tw-text-teal-600",
      },
      {
        variant: "naked",
        colorScheme: "teal",
        className: "tw-bg-transparent hover:tw-bg-teal-50 tw-text-teal-600",
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "md",
      colorScheme: "zinc",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof button> {
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  asChild?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild,
      className,
      variant,
      colorScheme,
      size,
      rightIcon,
      leftIcon,
      children,
      disabled,
      isLoading,
      loadingText,
      ...props
    },
    ref,
  ) => {
    //  A bit hacky but we want a white one for zinc primary and black for zinc secondary / tertiary
    const spinnerColor =
      (colorScheme ?? (!variant || variant === "primary")) ? "white" : "zinc";

    const Loader = () => (
      <>
        <Spinner
          colorScheme={spinnerColor}
          size={size}
          className={loadingText ? "tw-mr-2" : ""}
        />
        {loadingText}
      </>
    );

    if (asChild) {
      const Child = React.Children.only(children);
      if (!isValidElement(Child)) {
        throw new Error("Child of Button must be a ReactElement");
      }
      const Inner = React.cloneElement(
        Child,
        Child.props,
        <>
          {leftIcon && (
            <ButtonInnerIcon className="tw-mr-0.5">{leftIcon}</ButtonInnerIcon>
          )}
          {isLoading ? <Loader /> : Child.props.children}
          {rightIcon && (
            <ButtonInnerIcon className="tw-ml-0.5">{rightIcon}</ButtonInnerIcon>
          )}
        </>,
      );
      return (
        <Slot
          className={cn(button({ variant, size, colorScheme }), className)}
          {...props}
        >
          {Inner}
        </Slot>
      );
    } else {
      return (
        <button
          disabled={disabled ? disabled : isLoading || false}
          className={cn(button({ variant, size, colorScheme }), className)}
          {...props}
          ref={ref}
        >
          {leftIcon && (
            <ButtonInnerIcon className="tw-mr-0.5">{leftIcon}</ButtonInnerIcon>
          )}
          {isLoading ? <Loader /> : children}
          {rightIcon && (
            <ButtonInnerIcon className="tw-ml-0.5">{rightIcon}</ButtonInnerIcon>
          )}
        </button>
      );
    }
  },
);

Button.displayName = "Button";
